import React, {useState} from 'react';
import { Trans } from "@lingui/macro";
import { useParams, } from 'react-router-dom'
import {
    ExamSubmissionFile,
    SubmissionDetails,
    uploadExamSubmissionFile,
    submitExamSubmission,
} from '../api'  

import './SubmitFiles.css';

function SubmitFiles() {
    const { id } = useParams<string>()
    const [ uploadedFileCount, setUploadedFileCount ] = useState<number>(0);
    const [ totalFileCount, setTotalFileCount ] = useState<number>(0);
    
    async function handleSubmit(formData) {
        formData.preventDefault();
        const form_object = formData.currentTarget;

        let exam_submission_files: ExamSubmissionFile[] = [];
        const files = formData.currentTarget.elements.fileField.files;
        setUploadedFileCount(0);
        setTotalFileCount(files.length);

        let sd = new SubmissionDetails();

        sd.name = formData.currentTarget.elements.submissionNameField.value;
        sd.exam_submitter_secret = id!;

        await submitExamSubmission(
            id!,
            sd,
        ).then(res => {
            sd = res
        })

        for (var file of files) {
            const exam_submission_file = new ExamSubmissionFile();
            exam_submission_file.filename = file.name;
            exam_submission_file.submission_secret = sd.secret;
            exam_submission_file.file = file;
            
            const file_upload_result = await uploadExamSubmissionFile(id!, exam_submission_file)
            if (file_upload_result.submission_secret != null) {
                exam_submission_files.push(file_upload_result);
                setUploadedFileCount(exam_submission_files.length);
            }
        }

        form_object.reset();
    }

  return (
    <div className='SubmitFiles'>
        <h2><Trans>Augšupielādē darbu bildes:</Trans></h2>
        <p><Trans>Šobrīd augšupielādēti</Trans> <b>{uploadedFileCount}/{totalFileCount}</b> <Trans>faili</Trans></p>
        
        <fieldset disabled={uploadedFileCount!=totalFileCount}>
            <form className='SubmitterForm' onSubmit={handleSubmit}>
                <input 
                    type="file"
                    id="fileField"
                    name="fileField"
                    multiple
                />
                
                <div className='SubmissionDetails'>
                    <div className='SubmissionDetailsForm'>
                        <label htmlFor="submissionNameField">
                            <Trans>Iesūtījuma nosaukums:</Trans>
                        </label>
                        <input 
                            type='text'
                            id='submissionNameField' 
                            name='submissionNameField'
                        />
                    </div>
                    
                    <input 
                        type='submit'
                        className='SubmitButton' 
                    />
                </div>
            </form>
        </fieldset>
    </div>
  );
}

export default SubmitFiles;