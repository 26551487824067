import React, { useEffect, useState } from 'react';
import { Trans } from "@lingui/macro";
import { useParams } from 'react-router-dom'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  getRandomPage,
  getSpecificFilePage,
  getAllPages,
  GradedPage,
  REACT_APP_LSMAT_API_URL,
} from '../api'

import './Grade.css';

const defaultData: GradedPage[] = []

const columnHelper = createColumnHelper<GradedPage>()



function Grade() {
  const { id } = useParams()
  const [data, setData] = React.useState(() => [...defaultData])
  const [specificFileCode, setSpecificFileCode] = useState<string>('');
  
  const columns = [
    columnHelper.accessor('secret', {
      header: () => <span><Trans>Labotais darbs</Trans></span>,
      cell: secret => <a
       href={`/judge/${id}/grade/${secret.getValue()}`}
      ><Trans>Rediģēt</Trans></a>,
    }),
    columnHelper.accessor('exam_code', {
      header: () => <span><Trans>Eksāmena kods</Trans></span>,
    }),
    columnHelper.accessor('submission_file_secret', {
      header: () => <span><Trans>Fails</Trans></span>,
      cell: secret => <a 
        href={`${REACT_APP_LSMAT_API_URL}/files/${secret.getValue()}`}
        target="_blank"
      ><Trans>Apskatīt</Trans></a>,
    }),
    columnHelper.accessor('exam_section', {
      header: () => <span><Trans>Eksāmena sadaļa</Trans></span>,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  async function redirectRandomPage() {
    const new_page: GradedPage = await getRandomPage(id!);
    
    window.location.href = `/judge/${id}/grade/${new_page.secret}`;
  }

  async function redirectSpecificDoc() {
    const new_page: GradedPage = await getSpecificFilePage(id!, specificFileCode);
    
    window.location.href = `/judge/${id}/grade/${new_page.secret}`;
  }

  useEffect(() => {
    getAllPages(id!).then(res => {
      setData(res);
      });
  }, [])

  return (
    <div className='Grade'>
        <button 
          onClick={redirectRandomPage}
          className='RandomPageButton'  
        >
          <Trans>Nejaušs fails</Trans>
        </button>
        <div className='TopGradingSection'>
            <button 
              onClick={redirectSpecificDoc}
              className='RandomPageButton'  
            >
              <Trans>Konkrēts fails:</Trans>
            </button>
            <input 
              type='text'
              placeholder='Faila kods'
              value={specificFileCode}
              onChange={(e) => setSpecificFileCode(e.target.value)}
                />
        </div>
        <div className='TableContainer'>
            <table className='JudgedPagesTable'>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
    </div>
  );
}

export default Grade;