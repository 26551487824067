export const REACT_APP_LSMAT_API_URL: string = (process.env.REACT_APP_LSMAT_API_URL as string)


export class ExamTask{
    exam_section_name: string = '';
    name: string = '';
    max_points: number = 0;
    solution: string = '';

    points: number = 0;
};

export class ExamSection{
    exam_name: string = '';
    name: string = '';
    score_weight: number = 1;
    secret: string = '';   
    
    exam_tasks: ExamTask[] = [];
};

export class Exam{
    name: string = '';
    event_name: string = '';
    secret: string = '';    

    exam_sections: ExamSection[] = [];
};

export class TaskGrade{
    name: string = '';
    graded_page_secret: string = '';
    ungraded_pages: number = 0;
};

export class ExamSubmitter{
    name: string = '';
    exam_name: string = '';
    email_address: string = '';
    rsvp_option_name: string = '';
    secret: string = '';    
};

export class ExamSubmissionFile { // TODO: get from common SwaggerFile
    filename: string = '';
    submission_secret: string = '';
    file;
    rotation: number = 0;
    secret: string = '';

    times_graded: number = 0;
};

export class SubmissionDetails{
    name: string = '';
    gmt0_submitted: string = '';
    exam_submitter_secret: string = '';
    secret: string = '';

    submission_files: ExamSubmissionFile[] = [];
};

export class Judge {
    name: string = '';
    email_address: string = '';
    secret: string = '';
    
    ungraded_pages: number = 0;
    graded_pages: number = 0;
};

export class GradedPage {
    secret: string = '';
    exam_code: string = '';
    judge_secret: string = '';
    submission_file_secret: string = '';
    exam_section: ExamSection | null = null;
    comment: string = '';
    
    exam_secret: string = '';

    assign_to_self: boolean = true;
}

export interface RsvpOption {
    id: number
    name: string
    affirmative: boolean
    limit: number
    description: string
    image: ArrayBuffer
    wgs84_lat: number
    wgs84_lon: number
    
    applied: number
    
    selected: boolean
}

export interface RsvpOptions {
    event_name: string
    rsvp_options: RsvpOption[]
};

export function getFileUrl(
    file_secret: string,
): string {
    return `${REACT_APP_LSMAT_API_URL}/files/${file_secret}`
}

export async function setPageGrade(
    judge_secret: string,
    page_secret: string,
    graded_page: GradedPage,
): Promise<GradedPage> {
    graded_page.judge_secret = judge_secret;
    graded_page.secret = page_secret;

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/judge/${judge_secret}/grade/${page_secret}`, 
        {
            method: 'POST',
            body:   JSON.stringify(graded_page),
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    let response: Response; // TODO: make boilerplate
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: GradedPage = await response!.json();

    return result;
};


export async function getExam(
    exam_secret: string,
): Promise<Exam> {

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/exam/${exam_secret}`, 
        {
            method: 'GET',
        }
    )

    let response: Response; // TODO: make boilerplate
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: Exam = await response!.json();

    return result;
};

export async function getAllPages(
    judge_secret: string,
): Promise<GradedPage[]> {

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/judge/${judge_secret}/pages`, 
        {
            method: 'GET',
        }
    )

    let response: Response; // TODO: make boilerplate
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: GradedPage[] = await response!.json();

    return result;
};

export async function getPageDetails(
    judge_secret: string,
    page_secret: string,
): Promise<GradedPage> {

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/judge/${judge_secret}/page/${page_secret}`, 
        {
            method: 'GET',
        }
    )

    let response: Response; // TODO: make boilerplate
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: GradedPage = await response!.json();

    return result;
};

export async function createNewpage(
    judge_secret: string,
    submission_file_secret: string,
): Promise<GradedPage> {
    const graded_page: GradedPage = new GradedPage();
    graded_page.judge_secret = judge_secret;
    graded_page.submission_file_secret = submission_file_secret;

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/judge/${judge_secret}/newpage`, 
        {
            method: 'POST',
            body:   JSON.stringify(graded_page),
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    let response: Response; // TODO: make boilerplate
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: GradedPage = await response!.json();

    return result;
};

export async function getRandomPage(
        judge_secret: string,
    ): Promise<GradedPage> {
        const request: RequestInfo = new Request(
            `${REACT_APP_LSMAT_API_URL}/judge/${judge_secret}/grade/random`, 
            {
                method: 'GET',
            }
        )
    
        let response: Response;
        await fetch(request).then((res) => {
            if (res.ok) {
                response = res;
            }
          })
    
        const result: ExamSubmissionFile = await response!.json();

        const graded_page = await createNewpage(judge_secret, result.secret);
        console.log(graded_page);

        return graded_page;
};

export async function getSpecificFilePage(
        judge_secret: string,
        file_secret: string
    ): Promise<GradedPage> {
        const result: ExamSubmissionFile = new ExamSubmissionFile();
        result.secret = file_secret;

        const graded_page = await createNewpage(judge_secret, result.secret);
        console.log(graded_page);
        
        return graded_page;
};

export async function uploadExamSubmissionFile(
    secret: string,
    sf: ExamSubmissionFile,
): Promise<ExamSubmissionFile>  {
    let i = 0;
    while (i < 50) {
        i++;
        let formData = new FormData();
        formData.append(sf.filename, sf.file);

        const request: RequestInfo = new Request(
            `${REACT_APP_LSMAT_API_URL}/submission/${sf.submission_secret}/upload`, 
            {
                method: 'POST',
                body:   formData
            }
        )

        let response: Response = await fetch(request)
        if (response.ok) {
            
        }
        else {
            await new Promise(r => setTimeout(r, 400));
            continue;
        }

        const result: ExamSubmissionFile = await response!.json();
        return result
    }
    window.alert("Kļūda");
    return Promise.reject()
};

export async function submitExamSubmission(
    secret: string,
    sd: SubmissionDetails,
): Promise<SubmissionDetails>  {

    const request: RequestInfo = new Request(
        `${REACT_APP_LSMAT_API_URL}/submitter/${secret}/submit`, 
        {
            method: 'POST',
            body:   JSON.stringify(sd),
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    let response: Response;
    await fetch(request).then((res) => {
        if (res.ok) {
            response = res;
        }
      })

    const result: SubmissionDetails = await response!.json();

    return result
};

async function getRsvpOptions(
    secret
): Promise<RsvpOptions> {
    const request: RequestInfo = new Request(
        REACT_APP_LSMAT_API_URL + '/rsvp/options/get' + '?' + 'secret=' + secret, 
        {
            method: 'GET'
        }
    )

    const response: Response = await fetch(request)
    const result: RsvpOptions = await response.json()

    return result
};