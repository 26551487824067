import React from 'react';
import { Trans } from "@lingui/macro";
import { useParams } from 'react-router-dom'

import './Dashboard.css';

function Dashboard() {
    const { id } = useParams()

  return (
    <div>
        <Trans>Darbu labošanas lietas</Trans>
    </div>
  );
}

export default Dashboard;