import React, { useState, useTransition } from 'react';
import { Trans, t } from "@lingui/macro";
import { CiMenuBurger } from "react-icons/ci";
import {
  getRandomPage,
  GradedPage
} from '../api'

import { useParams } from 'react-router-dom'

import Logo from '../Logo'
import './Header.css';

function DesktopNav({ sections }) {
  return (
    <nav className='DesktopNav'>
      <ul>
          <>
            {sections.map(section => (
              <li><a href={section.href} className='NavEntry'>{section.name}</a></li>
            ))}
          </>
      </ul>
  </nav>
  );
}

function MobileNav({ sections }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='MobileNav'>
      <CiMenuBurger onClick={() => setIsOpen(!isOpen)} className='BurgerMenu' size={60}/>
      {isOpen && (
        <div className='overlay' onClick={() => setIsOpen(!isOpen)}>
          <ul className='MobileMenu'>
            <>
              {sections.map(section => (
                <li><a href={section.href}>{section.name}</a></li>
              ))}
            </>
          </ul>
        </div>
      )}
    </div>
  );
}

function Header({sections}) {
  const { id } = useParams()

  async function redirectRandomPage() {
    const new_page: GradedPage = await getRandomPage(id!);
    
    window.location.href = `/judge/${id}/grade/${new_page.secret}`;
  }

  const corrected_sections = sections;
  corrected_sections.forEach((section) => {
    section.href = section.href.replace(':id', id);
  });

  return (
    <header className="Header">
        <div className='HeaderList'>
          <Logo/>
          <DesktopNav sections={sections}/>
          <MobileNav sections={sections}/>
          <button onClick={redirectRandomPage}><Trans>Jauns darbs</Trans></button>
        </div>
        <hr className='DivLine'/>
    </header>
  );
}

export default Header;
