import React, { useEffect, useState } from 'react';
import { Trans } from "@lingui/macro";
import { useParams } from 'react-router-dom'

import {
  getPageDetails,
  getExam,
  GradedPage,
  Exam,
  getFileUrl,
  ExamSection,
  ExamTask,
  setPageGrade,
} from '../api'

import './GradePage.css';

function GradePage() {
  const { id, grade_page_secret } = useParams()
  const [ gradedPage, setGradedPage ] = useState<GradedPage>(new GradedPage());
  const [ exam, setExam ] = useState<Exam | null>(null);
  const [ fileUrl, setFileUrl ] = useState<string | null>(null);

    
  async function handleSubmit(formData) {
    formData.preventDefault();
    const form_object = formData.currentTarget;

    let graded_page: GradedPage = gradedPage!
    console.log(graded_page)
    await setPageGrade(id!, grade_page_secret!, graded_page);
    
    window.location.href = `/judge/${id}/grade/`;
}

  function getExamSection(val) : ExamSection | null {
    var result = exam?.exam_sections?.filter(obj => {
      return obj.name === val;
    })
    if (result) {
      return result[0];
    }
    return new ExamSection()
  }
  function setExamSection(val) {
    setGradedPage({...gradedPage, exam_section: getExamSection(val.target.value)})
  }

  function getExamTask(val) : ExamTask | null {
    var result = gradedPage?.exam_section!.exam_tasks!.filter(obj => {
      return obj.name === val;
    })
    if (result) {
      return result[0];
    }
    return new ExamTask()
  }
  function setExamTaskPoints(val, task_name) {
    const task_index = gradedPage.exam_section!.exam_tasks!.indexOf(getExamTask(task_name)!); // TODO: cleanup
    const new_exam_tasks = gradedPage.exam_section!.exam_tasks.map((c, i) => {
      if (i == task_index) {
        return {...c,
          points: val.target.value
        };
      }
      else {
        return c;
      }
    })
    setGradedPage({...gradedPage, 
      exam_section: {...gradedPage.exam_section!, 
        exam_tasks: new_exam_tasks
      }
    })
  }

  function setExamCode(val) {
    setGradedPage({...gradedPage, exam_code: val.target.value})
  }

  useEffect(() => {
    getPageDetails(id!, grade_page_secret!).then(res => {
        setGradedPage(res);
        
        const file_url = getFileUrl(res!.submission_file_secret);
        setFileUrl(file_url!);

        getExam(res.exam_secret).then(res2 => {
          setExam(res2)
        });
      });
  }, [])
  return (
    <div className='GradePage'>
        <div className='ExamFile'>
          <img className='ExamFileImage' src={fileUrl!}/>
          <embed className='ExamFileDoc' src={fileUrl!}/>
          <a href={fileUrl!} target="_blank" rel="noopener noreferrer">
            <h3>Lejupielādēt</h3>
          </a>
          <h3><Trans>Faila kods:</Trans> {grade_page_secret}</h3>
        </div>
        <div className='TaskGradings'>
            <form className='SubmitterForm' onSubmit={handleSubmit}>
            
              <select 
                className='SectionSelect'
                value={gradedPage?.exam_section?.name}
                onChange={(e) => setExamSection(e)}
              >
              <option value="" selected disabled hidden><Trans>Atlasi darba sadaļu</Trans></option>
                {exam?.exam_sections?.map(section => (
                  <option value={section.name}>{section.name}</option>
                ))}
              </select>
                <input 
                  type='text'
                  placeholder='Kods'
                  defaultValue='AT-'
                  value={gradedPage!.exam_code}
                  onChange={(e) => setExamCode(e)}
                />
                <input 
                    type='submit'
                    className='SubmitButton' 
                />
                  { gradedPage?.exam_section?.name && <div className='TaskDetails'>
                    {gradedPage?.exam_section?.exam_tasks?.map(task => (
                      <div className='TaskEntry'> 
                        <p>{task.name}</p>
                        <input 
                          className='PointInput'
                          type='number'
                          step="any"
                          min={0}
                          max={task.max_points}
                          id={task.name}
                          name={task.name}
                          value={getExamTask(task.name)?.points}
                          onChange={(e) => setExamTaskPoints(e, task.name)} /> no {task.max_points}
                      </div>
                    ))}
                  </div>}
                  
            </form>
        </div>
    </div>
  );
}

export default GradePage;