import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Trans } from "@lingui/macro";

import './App.css';

import Layout from './layout/Layout'
import Dashboard from './judge/Dashboard';
import SubmitFiles from './submitter/SubmitFiles';
import Grade from './judge/Grade';
import GradePage from './judge/GradePage';

const judge_sections = [ // TODO: add localisation for menu entries
  {name: 'Pārskats', href: '/judge/:id/'},
  {name: 'Labot darbus', href: '/judge/:id/grade/'},
]
const submitter_sections = [ 
  {name: 'Pārskats', href: '/submitter/:id/'},
]


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/judge/:id/" element={<Layout sections={judge_sections}/>}>
            <Route index element={<Dashboard/>} />
            <Route path="grade/" >
              <Route index element={<Grade/>} />
              <Route path=':grade_page_secret/' element={<GradePage/>} />
            </Route>
          </Route>
          <Route path="/submitter/:id/" element={<Layout sections={submitter_sections}/>}>
            <Route index element={<SubmitFiles/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
