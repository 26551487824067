import React from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import './Layout.css'

function Layout({sections}) {
  
  return (
    <div className='Layout'>
        <Header sections={sections}/>
        <Outlet />
    </div>
  );
}

export default Layout;